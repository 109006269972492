import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import Blue from '../../assets/svg/scoreBuro_B.svg'
import Green from '../../assets/svg/scoreBuro_G.svg'
import Orange from '../../assets/svg/scoreBuro_O.svg'
import Red from '../../assets/svg/scoreBuro_R.svg'
import Yellow from '../../assets/svg/scoreBuro_Y.svg'

function ArcoirisAppFinanciera({ bcScore }) {
    var currentColor = Red;
    var color = "#FFFFFF";
    if(bcScore <= 517){
        currentColor = Red; 
        color ="#f44336";
    } 
    if(bcScore >= 518 && bcScore <= 579){  
        currentColor = Orange; 
        color ="#f47d02";}
    if(bcScore >= 580 && bcScore <= 672){ 
        currentColor = Yellow; 
        color ="#ffce00";}
    if(bcScore >= 673 && bcScore <= 734){
        currentColor = Green; 
        color ="#4caf50";}
    if(bcScore >= 735){ 
        currentColor = Blue; 
        color = "#2196f3"; 
    }

    const ScoreContainer = styled.div`
        background-image: url(${currentColor});
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        margin-left: auto;
        margin-right: auto;
        height: 120px;
        width: 250px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-content: center;
        text-align: center;
        & span {
            font-size: 3em;
            font-weight: bold;
        }
` 
    return (
        <ScoreContainer item style={{align: "center"}}>
            <span id="evaluacion-bcScore" style={{ color: color }}>{bcScore}</span>
        </ScoreContainer>        
    )
}

ArcoirisAppFinanciera.propTypes = {
    bcScore: PropTypes.number
}

export default ArcoirisAppFinanciera

